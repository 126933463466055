import translations from "@/utilities/translations.json";
import globals from "@/utilities/globals.json";

export function translate(key) {
    let text = "";
    try {
        text = translations[key][globals.lang];
    } catch (error) {
        console.error(`No translation found for key: ${key}`);
        text = key;
    }
    return text;
}

export function setLanguage(lang) {
    globals.lang = lang;
    localStorage.setItem("lang", lang);
}

export function getValueForMail(options, choosen) {
    if (!choosen) return choosen;
    try {
        let returnValue = [];
        options.forEach((option) => {
            if (typeof choosen === "string" && `${option.value}` === choosen) {
                returnValue.push(option.title ? translate(option.title) : option.label);
            } else if (typeof choosen !== "string" && choosen.includes(option.value)) {
                returnValue.push(option.title ? translate(option.title) : option.label);
            }
        });
        return returnValue.length > 1 ? returnValue : returnValue[0] || choosen;
    } catch (e) {
        console.error(`Error in getValueForMail: ${e}`);
        return choosen;
    }
}

export function changeLang() {
    if (globals.lang === "en") {
        globals.lang = "de";
        localStorage.setItem("lang", "de");
    } else {
        globals.lang = "en";
        localStorage.setItem("lang", "en");
    }
}

export function getLang() {
    return globals.lang;
}

export function getCompanyName() {
    return globals.companyName;
}

export async function initCompany() {
    return new Promise((res) => {
        if (window.location.hostname.includes("test-daimler-truck")) {
            globals.companyName = "General";
            globals.companyShortage = "gn";
            globals.host = "http://192.168.111.51:14080";
        } else if (window.location.hostname.includes("daimler")) {
            globals.companyName = "Daimler Truck";
            globals.division = "daimlertrucksorder";
            globals.companyShortage = "dt";
            globals.host = `${window.location.origin}/ordersite/api`;
        } else if (window.location.hostname.includes("mercedes-benz")) {
            globals.companyName = "Mercedes-Benz";
            globals.division = "mercedesbenzorder";
            globals.companyShortage = "mb";
            globals.host = `${window.location.origin}/ordersite/api`;
        } else if (window.location.hostname.includes("aeordersite")) {
            globals.companyName = "General";
            globals.companyShortage = "gn";
            globals.host = window.location.origin;
        } else {
            //globals.companyName = "Daimler Truck";
            //globals.companyShortage = "dt";

            globals.companyName = "General";
            globals.companyShortage = "gn";

            //globals.companyName = "Mercedes-Benz";
            //globals.companyShortage = "mb";

            globals.division = "orderlocal";
            globals.host = "https://192.168.111.51:25443";
        }
        res(true);
    });
}

export function setTitle(title) {
    document.title = translate(title);
}

export function getDivision() {
    return globals.division;
}

export function getCompanyShortage() {
    return globals.companyShortage;
}

export function getTodayDate() {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    day = `${day}`.length < 2 ? `0${day}` : day;
    month = `${month}`.length < 2 ? `0${month}` : month;
    year = `${year}`.substring(2);
    return `${day}.${month}.${year}`;
}

export function setRedirected(value) {
    globals.redirected = value;
}

export function getRedirected() {
    return globals.redirected;
}

export async function initSite() {
    await initCompany();
    await initLang();
}

export function setToken(token) {
    globals.token = token;
    let newUI = parseJwt(token);
    newUI.provisioning = parseProvisioning(newUI.provisioning);
    globals.userInfo = newUI;
}

export function getUserInfo() {
    return globals.userInfo;
}

export function getHost() {
    return globals.host;
}

export function getToken() {
    return globals.token;
}

async function initLang() {
    return new Promise((res) => {
        const storedLang = localStorage.getItem("lang");
        if (storedLang) {
            globals.lang = storedLang;
        }
        res(true);
    });
}

function parseJwt(token) {
    try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error(`Error in parseJwt: ${e}`);
    }
}

function parseProvisioning(provisioning) {
    try {
        let newEntrys = {};
        let entrys = provisioning.split("CorpDirEntry [")[1].split(",");
        entrys[entrys.length - 1] = entrys[entrys.length - 1].slice(0, -1);
        for (let i = 0; i < entrys.length; i++) {
            const splittedEntry = entrys[i].trim().split("=");
            newEntrys[splittedEntry[0]] = splittedEntry[1];
        }
        return newEntrys;
    } catch (e) {
        console.error(`Error in parseProvisioning: ${e}`);
    }
}

export function isDaimler() {
    return globals.companyShortage === "dt";
}

export function isMercedes() {
    return globals.companyShortage === "mb";
}

export function isGeneral() {
    return globals.companyShortage === "gn";
}

export function getCaptchaToken() {
    return new Promise((resolve, reject) => {
        if (typeof window.grecaptcha !== "undefined") {
            const test = window.grecaptcha.enterprise.execute("6LdwsE0qAAAAAPDVZuxagSviFX61gSZtcS3OeGUO", {
                action: "LOGIN",
            });
            test.then(resolve).catch(reject);
        } else {
            reject(new Error("reCAPTCHA not loaded"));
        }
    });
}
