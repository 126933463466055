import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { TrackJS } from "trackjs";

if (process.env.NODE_ENV !== "development") {
    TrackJS.install({
        token: "7e6109dbdc284e37bb1e9672da5f3f64",
        // eslint-disable-next-line no-undef
        version: `ordersite-vue`,
        network: { error: false },
        forwardingDomain: "jserrors.meetyoo.com",
    });
}

createApp(App).use(Quasar, quasarUserOptions).use(router).mount("#app");
