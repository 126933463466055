<template>
    <router-view />
</template>

<script setup></script>

<style lang="scss">
@import "@/styles/forms.css";
@import "@/styles/modal.css";
@import "@/styles/style.css";
</style>
