import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        input: {
            inputClass: "no-border",
        },
    },
    plugins: {
        Notify,
    },
};
